import React from 'react';
import Button from 'react-bootstrap/Button';
import ZonedDatePicker from './ZonedDatePicker';
import Form from 'react-bootstrap/Form';
import base64 from 'react-native-base64';

class Scheduler extends React.Component {
  constructor(props) {
  super(props);
  this.state = {
                startDate: { timezone: {}},
                endDate: { timezone: {}},
                description: '',
                gText: 'Copy Google Link',
                iText: 'Copy iCal Link',
                hasDate: false
                };

  this.generate = this.generate.bind(this);
  this.handleDateChange = this.handleDateChange.bind(this);
  this.copyGCal = this.copyGCal.bind(this);
  this.copyICal = this.copyICal.bind(this);
  this.handleChange = this.handleChange.bind(this);
  }

  async componentDidMount() {
    await this.props.setBanner('Calendar Generator')
  }

  async handleChange(e) {
    let change = {}
    change[e.target.name] = e.target.value
    await this.setState(change)

  }

  async handleDateChange(startObj, endObj, diff) {

    await this.setState({startDate: startObj, endDate: endObj, hasDate: diff > 0});
  }



  formatGcalDate(d) {
    return d.year + d.month + d.day + 'T' + d.hour + d.minute + '00'
  }

  formatICSDate(d) {
    return 'TZID=' + d.timezone.gname + ':' + d.year + d.month + d.day + 'T' + d.hour + d.minute + '00';
  }

  async generate() {
    console.log(this.state);


    var currentDate = new Date();
    var text = 'BEGIN:VCALENDAR\nVERSION:2.0\nPRODID:-//godshallcorp/calgenerator/WOO v1.0//EN\n';
    text = text + VTIMEZONES[this.state.startDate.timezone.name];
    text = text + '\nBEGIN:VEVENT\nuid@juliegodshall.com\nDTSTAMP:' + currentDate.toISOString() + '\n';
    text = text + 'ORGANIZER;CN=' + this.state.name + ':MAILTO:' + this.state.email + '\n';
    text = text + 'DTSTART;' + this.formatICSDate(this.state.startDate) + '\n';
    text = text + 'DTEND;' + this.formatICSDate(this.state.endDate) + '\nSUMMARY:' + this.state.eventName + '\nLOCATION:' + this.state.location + '\nDESCRIPTION:' + this.state.description.replace(/[\n\r]/g, '\\n');

    if (this.state.reminder > 0) {
      text = text + '\nBEGIN:VALARM\nACTION:DISPLAY\nDESCRIPTION:REMINDER\nTRIGGER:-PT' + this.state.reminder + 'H\nEND:VALARM'
    }

    text = text + '\nEND:VEVENT\nEND:VCALENDAR';

    var encodedText = window.location.hostname + '/links/' + base64.encode(text);

    //gcal
    text = 'https://calendar.google.com/calendar/r/eventedit?';


    text = text + 'text=' + this.state.eventName;
    text = text + '&dates=' + this.formatGcalDate(this.state.startDate) + '/' + this.formatGcalDate(this.state.endDate) + '&ctz=' + this.state.startDate.timezone.gname;
    text = text + '&details=' + this.state.description;
    text = text + '&location=' + this.state.location;

    await this.setState({gCal: encodeURI(text), encodedText: encodedText, iCal: text});

  }

  GetDateFormat = (d) => {
    return d;
  }

  async copyICal() {
    await this.generate();
    var self = this;
    this.copyToClipboard(this.state.encodedText);
    this.setState({iText: 'Copied!'});
    setTimeout(() => {
      self.setState({iText: 'Copy iCal Link'});
    }, 3000)

  }

  async copyGCal() {
    await this.generate();
    var self = this;
    this.copyToClipboard(this.state.gCal);
    this.setState({gText: 'Copied!'});
    setTimeout(() => {
      self.setState({gText: 'Copy Google Link'});
    }, 3000)
  }

  copyToClipboard(text) {
      var dummy = document.createElement("textarea");
      document.body.appendChild(dummy);
      dummy.value = text;
      dummy.select();
      document.execCommand("copy");
      document.body.removeChild(dummy);
  }

  render() {
    return (
      <div>
      <div className="row">
      <div className = "col-md-6">
        <div className="row">
          <div className="col-md-8">
          <Form.Group>
            <Form.Control type="text" placeholder="Event name" name="eventName" value={this.state.eventName} onChange={this.handleChange} />
          </Form.Group>
          </div>
        </div>
        <div className="row">
          <div className="col-md-8">
          <Form.Group>
            <Form.Control type="text" placeholder="Location" name="location" value={this.state.location} onChange={this.handleChange} />
          </Form.Group>
          </div>
        </div>
        <div className="row">
          <div className="col-md-8">
          <Form.Group>
            <Form.Control as="textarea" rows="3" placeholder="Description" name="description" value={this.state.description} onChange={this.handleChange} />
          </Form.Group>
          </div>
        </div>


      </div>
        <div className="col-md-6">


          <ZonedDatePicker handleDateChange={this.handleDateChange} />

        </div>
        </div>

        <Form.Group className="generation">

          <Button variant="primary" disabled={!this.state.hasDate} onClick={this.copyICal}>
            {this.state.iText}
          </Button>

          <Button variant="primary" disabled={!this.state.hasDate} onClick={this.copyGCal}>
            {this.state.gText}
          </Button>
        </Form.Group>
      </div>
    );
  }
}

var VTIMEZONES = {
'US/Eastern': 'BEGIN:VTIMEZONE\nTZID:America/New_York\nLAST-MODIFIED:20050809T050000Z\nBEGIN:DAYLIGHT\nDTSTART:20070311T020000\nRRULE:FREQ=YEARLY;BYMONTH=3;BYDAY=2SU\nTZOFFSETFROM:-0500\nTZOFFSETTO:-0400\nTZNAME:EDT\nEND:DAYLIGHT\nBEGIN:STANDARD\nDTSTART:20071104T020000\nRRULE:FREQ=YEARLY;BYMONTH=11;BYDAY=1SU\nTZOFFSETFROM:-0400\nTZOFFSETTO:-0500\nTZNAME:EST\nEND:STANDARD\nEND:VTIMEZONE',
'US/Central': 'BEGIN:VTIMEZONE\nTZID:America/Chicago\nLAST-MODIFIED:20050809T050000Z\nBEGIN:DAYLIGHT\nDTSTART:20070311T020000\nRRULE:FREQ=YEARLY;BYMONTH=3;BYDAY=2SU\nTZOFFSETFROM:-0600\nTZOFFSETTO:-0500\nTZNAME:EDT\nEND:DAYLIGHT\nBEGIN:STANDARD\nDTSTART:20071104T020000\nRRULE:FREQ=YEARLY;BYMONTH=11;BYDAY=1SU\nTZOFFSETFROM:-0500\nTZOFFSETTO:-0600\nTZNAME:EST\nEND:STANDARD\nEND:VTIMEZONE',
'US/Mountain': 'BEGIN:VTIMEZONE\nTZID:America/Denver\nLAST-MODIFIED:20050809T050000Z\nBEGIN:DAYLIGHT\nDTSTART:20070311T020000\nRRULE:FREQ=YEARLY;BYMONTH=3;BYDAY=2SU\nTZOFFSETFROM:-0700\nTZOFFSETTO:-0600\nTZNAME:EDT\nEND:DAYLIGHT\nBEGIN:STANDARD\nDTSTART:20071104T020000\nRRULE:FREQ=YEARLY;BYMONTH=11;BYDAY=1SU\nTZOFFSETFROM:-0600\nTZOFFSETTO:-0700\nTZNAME:EST\nEND:STANDARD\nEND:VTIMEZONE',
'US/Pacific': 'BEGIN:VTIMEZONE\nTZID:America/Los_Angeles\nLAST-MODIFIED:20050809T050000Z\nBEGIN:DAYLIGHT\nDTSTART:20070311T020000\nRRULE:FREQ=YEARLY;BYMONTH=3;BYDAY=2SU\nTZOFFSETFROM:-0800\nTZOFFSETTO:-0700\nTZNAME:EDT\nEND:DAYLIGHT\nBEGIN:STANDARD\nDTSTART:20071104T020000\nRRULE:FREQ=YEARLY;BYMONTH=11;BYDAY=1SU\nTZOFFSETFROM:-0700\nTZOFFSETTO:-0800\nTZNAME:EST\nEND:STANDARD\nEND:VTIMEZONE',
'US/Alaska': 'BEGIN:VTIMEZONE\nTZID:America/Anchorage\nLAST-MODIFIED:20050809T050000Z\nBEGIN:DAYLIGHT\nDTSTART:20070311T020000\nRRULE:FREQ=YEARLY;BYMONTH=3;BYDAY=2SU\nTZOFFSETFROM:-0900\nTZOFFSETTO:-0800\nTZNAME:EDT\nEND:DAYLIGHT\nBEGIN:STANDARD\nDTSTART:20071104T020000\nRRULE:FREQ=YEARLY;BYMONTH=11;BYDAY=1SU\nTZOFFSETFROM:-0800\nTZOFFSETTO:-900\nTZNAME:EST\nEND:STANDARD\nEND:VTIMEZONE',
'US/Hawaii': 'BEGIN:VTIMEZONE\nTZID:Pacific/Honolulu\nLAST-MODIFIED:20050809T050000Z\nBEGIN:DAYLIGHT\nDTSTART:20070311T020000\nRRULE:FREQ=YEARLY;BYMONTH=3;BYDAY=2SU\nTZOFFSETFROM:-1000\nTZOFFSETTO:-0900\nTZNAME:EDT\nEND:DAYLIGHT\nBEGIN:STANDARD\nDTSTART:20071104T020000\nRRULE:FREQ=YEARLY;BYMONTH=11;BYDAY=1SU\nTZOFFSETFROM:-0900\nTZOFFSETTO:-1000\nTZNAME:EST\nEND:STANDARD\nEND:VTIMEZONE'

}
export default Scheduler;
