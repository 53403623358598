import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.css';
import Scheduler from './Scheduler';
import MailTo from './MailTo'
import SMSTo from './SMSTo'
import Display from './Display';

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
    this.setBanner = this.setBanner.bind(this);
  }

  async setBanner(s) {
    await this.setState({banner: s});
  }
  render() {
    return (
      <div>
      <div className="jumbotron">
        <h3>{this.state.banner}</h3>
      </div>
      <Router>
      <div className="App">
        <div className="container-fluid">
        <Switch>
          <Route exact path='/' render={(props) => (
            <Scheduler {...props} setBanner={this.setBanner} />
          )}  />
          <Route exact path='/links/:appt' render={(props) => (
            <Display {...props} setBanner={this.setBanner} />
          )}  />
          <Route exact path='/m/:address' render={(props) => (
            <MailTo {...props} />
          )}  />
          <Route exact path='/s/:address/:text' render={(props) => (
            <SMSTo {...props} isIos={false} />
          )}  />
          <Route exact path='/i/:address/:text' render={(props) => (
            <SMSTo {...props} isIos={true} />
          )}  />
        </Switch>
        </div>
      </div>
      </Router>
      </div>
    );
  }
}

export default App;
