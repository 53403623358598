import React from 'react';
import base64 from 'react-native-base64';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

class Display extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

      this.download = this.download.bind(this);
  }

  async componentDidMount() {
    this.props.setBanner('Download Event Reminder')
    var apptData = this.props.match.params.appt;

    var x = base64.decode(apptData);
    this.setState({appt : x});

  }

  download(event) {
    var filename = 'event.ics';
    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(this.state.appt));
    element.setAttribute('download', filename);
    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }

  render() {
    return (
      <div className="row">
      <Form.Group>
      <Button variant="primary" onClick={this.download}>
        Download iCal
      </Button>
      </Form.Group>
      </div>

    );

  }

}

export default Display;
