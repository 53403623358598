import React from 'react';

class MailTo extends React.Component {

  async componentDidMount() {
  }
  render() {
    var address = this.props.match.params.address;
    if (address.indexOf('mailto:') === -1 && address.indexOf('tel:') === -1 && address.indexOf('sms:') === -1) { return null; }
    window.location.href = address;
    return null;
  }
}

export default MailTo;
