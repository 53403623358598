import React from 'react';

class SMSTo extends React.Component {

  render() {
    var address = this.props.match.params.address;
    var text = this.props.match.params.text;
    var separator = this.props.isIos ? ';' : '?'
    window.location.href = 'sms:' + address + separator + text;
    return null;
  }
}

export default SMSTo;
