import React from 'react';
import DatePicker from 'react-datepicker';
import Form from 'react-bootstrap/Form';
import DateTime from 'luxon/src/datetime.js';

class ZonedDatePicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {timezone: ZONE_MAP[todayDateTime.offsetNameShort],
    startDate: new Date(),
    endDate: new Date()};

    this.handleStartChange = this.handleStartChange.bind(this);
    this.handleEndChange = this.handleEndChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleZoneChange = this.handleZoneChange.bind(this);
  }

  async handleStartChange(date) {
      await this.setState({startDate: date});
      await this.handleDateChange();

  }

  async handleEndChange(date) {
      await this.setState({endDate: date});
      await this.handleDateChange();
  }

  async handleDateChange() {
    if (this.state.endDate < this.state.startDate) { await this.setState({endDate: this.state.startDate});}

    var start = this.state.startDate;
    var startObj = {
          year: start.getFullYear(),
          month: ('0' + (start.getMonth()+1)).slice(-2),
          day: ('0' + start.getDate()).slice(-2),
          hour: ('0' + start.getHours()).slice(-2),
          minute: ('0' + start.getMinutes()).slice(-2),
          timezone: this.state.timezone
        }

    var end = this.state.endDate;
    var endObj = {
          year: end.getFullYear(),
          month: ('0' + (end.getMonth()+1)).slice(-2),
          day: ('0' + end.getDate()).slice(-2),
          hour: ('0' + end.getHours()).slice(-2),
          minute: ('0' + end.getMinutes()).slice(-2),
          timezone: this.state.timezone
        }

    var diff = end-start;
    await this.props.handleDateChange(startObj, endObj, diff);

  }


  async handleZoneChange(e) {
    await this.setState({timezone: ZONE_MAP[e.target.value]});
    await this.handleStartChange(this.state.startDate);
    await this.handleEndChange(this.state.endDate);
  }

  dstOffset(d) {
    return 0;
  }

  render() {

    var TIMEZONES = this.state.timezone.isDST ? DST_TIMEZONES : STANDARD_TIMEZONES;

    return (
      <div>
      <Form.Group>
      <label className="col-sm-2 col-form-label">Start: </label>
      <DatePicker
        className = "date-select"
        showTimeSelect
        selected={this.state.startDate}
        onChange={this.handleStartChange}
      />
      </Form.Group>
      <Form.Group>
      <label className="col-sm-2 col-form-label">End: </label>
      <DatePicker
        className = "date-select"
        showTimeSelect
        selected={this.state.endDate}
        onChange={this.handleEndChange}
      />
      </Form.Group>
      <Form.Group>
      <label className="col-sm-4 col-form-label">Reminder: </label>
      <select className = "date-select-small" value={this.state.reminder} onChange={(e) => this.setState({reminder: e.target.value})}>
          <option selected value="0">None</option>
          <option value="1">1 hour</option>
          <option value="12">12 hours</option>
          <option value="24">1 Day</option>
          </select>
      </Form.Group>
      <Form.Group>
      <label className="col-sm-2 col-form-label">Time Zone: </label>
      <select className = "date-select" onChange={this.handleZoneChange}>
        {TIMEZONES.map((z) => {
          if (z.name === this.state.timezone.name) {
            return (<option selected="selected" value={z.key}>{z.name}</option>)
          }
          return (<option value={z.key}>{z.name}</option>)
        })}


        </select>
      </Form.Group>
      </div>
    )
  }
}

export default ZonedDatePicker;

var today = new Date();
var todayDateTime = new DateTime(today);

var ZONE_MAP = {
  'EDT': {
    offset: -240,
    name: 'US/Eastern',
    gname: 'America/New_York',
    isDST: true
  },
  'CDT': {
    offset: -300,
    name: 'US/Central',
    gname: 'America/Chicago',
    isDST: true
  },
  'MDT': {
    offset: -360,
    name: 'US/Mountain',
    gname: 'America/Denver',
    isDST: true
  },
  'PDT': {
    offset: -420,
    name: 'US/Pacific',
    gname: 'America/Los_Angeles',
    isDST: true
  },
  'ADT': {
    offset: -480,
    name: 'US/Alaska',
    gname: 'America/Anchorage',
    isDST: true
  },
  'HDT': {
    offset: -540,
    name: 'US/Hawaii',
    gname: 'Pacific/Honolulu',
    isDST: true
  },
  'EST': {
    offset: -300,
    name: 'US/Eastern',
    gname: 'America/New_York',
    isDST: false
  },
  'CST': {
    offset: -360,
    name: 'US/Central',
    gname: 'America/Chicago',
    isDST: false
  },
  'MST': {
    offset: -420,
    name: 'US/Mountain',
    gname: 'America/Denver',
    isDST: false
  },
  'PST': {
    offset: -480,
    name: 'US/Pacific',
    gname: 'America/Los_Angeles',
    isDST: false
  },
  'AST': {
    offset: -540,
    name: 'US/Alaska',
    gname: 'America/Anchorage',
    isDST: false
  },
  'HST': {
    offset: -600,
    name: 'US/Hawaii',
    gname: 'Pacific/Honolulu',
    isDST: false
  }
}

var STANDARD_TIMEZONES = [
  {name: 'US/Eastern', key: 'EST'},
  {name: 'US/Central', key: 'CST'},
  {name: 'US/Mountain', key: 'MST'},
  {name: 'US/Pacific', key: 'PST'},
  {name: 'US/Alaska', key: 'AST'},
  {name: 'US/Hawaii', key: 'HST'}
]

var DST_TIMEZONES = [
  {name: 'US/Eastern', key: 'EDT'},
  {name: 'US/Central', key: 'CDT'},
  {name: 'US/Mountain', key: 'MDT'},
  {name: 'US/Pacific', key: 'PDT'},
  {name: 'US/Alaska', key: 'ADT'},
  {name: 'US/Hawaii', key: 'HDT'}
]
